<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-layout>
      <v-progress-linear
        color="#7CBF46"
        v-show="loader"
        :indeterminate="true"
      ></v-progress-linear>
    </v-layout>

    <v-layout wrap>
      <v-col cols="12" md="3">
        <UserMenu></UserMenu>
      </v-col>

      <v-col cols="12" md="9">
        <v-row>
          <v-col cols="12">
            <h3>User Prescriptions</h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
              <v-btn class="buttonStyle" dark to="/upload/user-prescription">
                  Add new Prescription
                </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="rowBg mt-5"
          >
             <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                       Prescription ID
                    </th>
                    <th class="text-left">
                        Hosiptal Name
                    </th>
                    <th class="text-left">
                        Action
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in userPrescriptions" :key="index">
                        <td>{{data.userPrescriptionId}}</td>
                        <td>{{data.hospitalName}}</td>
                        <td>
                          <v-btn x-small rounded circle v-on:click="viewPrescription(data)">view</v-btn>
                          <v-btn x-small rounded circle v-on:click="deletePrescription(data)" class="ml-3" color="error">delete</v-btn>
                        </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-dialog v-model="orderInfoDialog" width="600">
                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Prescription
                      <v-spacer></v-spacer>
                      <v-btn icon @click="orderInfoDialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text>
                      <v-layout wrap>
                        <v-col cols="12">
                          <v-row>
                            <template >
                              <v-col v-for="product in prescriptionProducts" :key="product.id" md="4">
                                <v-sheet
                                  height="300"
                                  style="background-color:#FAFAFA;border-radius:10px;"
                                >
                                  <v-img
                                    contain
                                    class="productImg"
                                    height="120"
                                    width="100%"
                                    :src="product.productImagePath"
                                    :to="'/product-detail/' + product.productId"
                                    style="background-color:#FFFFFF;"
                                  >
                                    <h6 align="center" class="priceStyle">
                                      {{ systemSetting.systemCurrency }}
                                      {{ product.price }}
                                    </h6>
                                  </v-img>

                                  <div class="mt-3 ml-3"  style="background-color:#FAFAFA">
                                    <h6>{{ product.name }}</h6>
                                    <div style="padding:10px; font-size:12px;">
                                      {{ getProductContent(product) }}
                                    </div>
                                    <p>
                                      <v-btn
                                        small
                                        align="center"
                                        class="buttonStyle"
                                        :to="'/product-detail/' + product.productId"
                                      >
                                        <v-icon class="mr-2" style="font-size:14px;">
                                          mdi-cart-outline
                                        </v-icon>
                                        Add to Cart
                                      </v-btn>
                                    </p>
                                  </div>
                                </v-sheet>
                              </v-col>
                            </template>
                          </v-row>
                        </v-col>
                      </v-layout>
                    </v-card-text>
                  </v-card>
        </v-dialog>

        <v-dialog v-model="deleteDialog" width="400">
                  <v-card>
                    <v-card-title class="text-h6 grey lighten-2">
                      Prescription
                      <v-spacer></v-spacer>
                      <v-btn icon @click="deleteDialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text>
                      <v-layout wrap>
                        <v-col cols="12">
                          <p> Are you sure you want to delete this prescription?</p>
                        </v-col>
                      </v-layout>
                      <v-layout wrap>
                        <v-col cols="12">
                          <v-btn small rounded circle @click="deleteP(singlePrescription.userPrescriptionId)">Yes</v-btn>
                          <v-btn small rounded circle @click="deleteDialog = false" class="ml-3" color="error">No</v-btn>
                        </v-col>
                      </v-layout>
                    </v-card-text>
                  </v-card>
        </v-dialog>

      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import UserMenu from "../others/UserMenu.vue";
import { Country, State } from "country-state-city";

export default {
  name: "UserPrescriptions",

  components: {
    UserMenu,
  },

  data: () => ({
    errorMessage: [],
    orderInfoDialog: false,
    deleteDialog: false,
    loader: false,
    checkbox: false,
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/#/overview/user/dashboard/en",
      },
      {
        text: "Prescription",
        disabled: true,
        href: "/#/prescription/dashboard/en",
      },
    ],
    
    id: "",
    error: "",
    states: [],
    userPrescriptions:[],
    selectedCountry: {},
    singlePrescription: {},
    systemSetting: {
      systemCurrency: "₦",
    },
    prescriptionProducts: []
  }),
  created() {
    this.getCountry();
    this.getUserPrescriptions();
  },
  methods: {
    getCountry() {
      this.country = Country.getAllCountries();
    },
    getCountryStates(evt) {
      this.country.forEach((element) => {
        if (element.name == evt) {
          this.states = State.getStatesOfCountry(element.isoCode);
        }
      });
    },
    getUserPrescriptions() {
      this.$http
        .get(`${process.env.VUE_APP_URL}all-prescriptions/` + localStorage.getItem("userId"))
        .then((response) => {
          this.userPrescriptions = response.data.data;
        });
    },
    viewPrescription(data) {
      this.singlePrescription = data;
      this.orderInfoDialog = true;
      const productIds = data.drugName.split(",");
      productIds.forEach(element => {
        this.$http
        .get(`${process.env.VUE_APP_URL}product/` + element)
        .then((response) => {
          this.prescriptionProducts.push(response.data.data.product);
        });
      });
    },

    getProductContent(product) {
      let body = this.stripTags(product.description);

      return body.length > 40 ? body.substring(0, 40) + "..." : body;
    },

    stripTags(text) {
      return text.replace(/(<([^>]+)>)/gi, "");
    },

    deletePrescription(data) {
      console.log(data);
      this.singlePrescription = data;
      this.deleteDialog = true;
    },

    deleteP(userPrescriptionId) {
        this.$http
        .delete(`${process.env.VUE_APP_URL}prescription/` + userPrescriptionId)
        .then(() => {
          this.getUserPrescriptions();
          this.deleteDialog = false;
        });
    }
  },
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 10px;
}

.iconStyle {
  font-size: 40px;
  color: #dd6464;
  transform: rotate(180deg);
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 12px;
  padding-left: 25px !important;
  padding-right: 25px !important;
  padding-top: 30px;
  padding-bottom: 30px;
}

li {
  font-size: 12px;
  margin-top: 5px;
}

.defaultAddressStyle {
  width: 130px;
  height: auto;
  background: rgba(17, 18, 18, 0.47);
  color: #ffffff;
  padding: 5px;
  border-top-left-radius: 5px;
}

.priceStyle {
  width: 90px;
  height: auto;
  font-size: 12px;
  background: linear-gradient(192.88deg, #111212 13.53%, #72bd61 90.69%);
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

</style>
